<template>
    <modal class="couponSelectModel" :isShow="isShow" @keyup.esc="hide">
        <div class="header">优惠券
            <div class="search-box">
                <div class="search"><input type="text" v-model="searchText" placeholder="输入券名称检索"></div>
                <div class="bnt-search">搜索</div>
            </div>
        </div>
        <div class="modal-body">
            <tabs class="panel" >
                <div class="panel-heading">
                    <div class="nav-box">
                        <tab-nav class="nav-li">现金券</tab-nav>
                        <tab-nav class="nav-li">商品券</tab-nav>
                    </div>
                </div>
                <div class="panel-body" v-scroll-anime="{up:'left-order-up',down:'left-order-down',liClass:'.item-li',marginH:16,paddingH:20,isRow:false}">
                    <tab-pane class="item-box clearfix">
                        <div class="item-li coupons" :class="{selected:item._selected}" @click="onTicket(item)"
                            v-for="item in getTicketList(1)" :key="item">
                            <div class="coupons-name verticalCenter">
                                <div class="money-ico">￥</div>
                                <div class="name inlineNowrap2 money">{{item.Eat_Price}}</div>
                            </div>
                            <div class="coupons-describe nowrap">
                                {{item.Tk_Name}}
                            </div>
                        </div>
                    </tab-pane>
                    <tab-pane class="item-box clearfix">
                        <div class="item-li coupons" :class="{selected:item._selected}" @click="onTicket(item)"
                            v-for="item in getTicketList(2)" :key="item">
                            <div class="coupons-name verticalCenter">
                                <div class="name inlineNowrap2">{{item.Tk_Name}}</div>
                            </div>
                            <div class="coupons-describe nowrap">
                                {{({0:'全额抵用菜品',1:'抵用金额'+item.DiscountValue,2:'折扣率'+item.DiscountValue+"%"})[item.DiscountType]}}
                            </div>
                        </div>
                    </tab-pane>
                </div>
                <div class="item-operation">
                    <div class="float-right">
                        <div class="bnt-fangxiang" ref="left-order-up"><i class="iconfont icon-fangxiang-up"></i></div>
                        <div class="bnt-fangxiang" ref="left-order-down"><i class="iconfont icon-fangxiang-down"></i></div>
                    </div>
                </div>
            </tabs>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">取消</button>
            <button class="btn btn-confirm" @click="confirm()">确认</button>
        </div>
    </modal>
</template>

<script>

/**现金券 或 商品券 选择 */
export default {
    name:"paymentCouponsModel",
    emits:["closeModel","confirm"],
    props:{
        isShow:Boolean,
        data:{
            default:{
                INTERFACE_TYPE: 7,
                PAY_NAME: "现金券",
                ticketInfos:[]
            }
        }
    },
    data(){
        return {
            /**券数据 */
            ticketInfos:[],
            /**搜索字段 */
            searchText:''
        }
    },
    mounted(){
        this.loadData();
        this.init();
    }, 
    watch: {
        isShow(newVal) {
            if(newVal){
                if(!this.ticketInfos || this.ticketInfos.length==0){
                    this.loadData();
                }
                this.init();
            }
        }
    },
    methods:{
        /**加载券模板信息 */
        loadData(){
            const loading = this.$loading({
                text: "数据加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            /**基础券模板信息 */
            this.$httpAES.post("Bestech.CloudPos.GetCrmBaseCoupons",{
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.ticketInfos=d.ResponseBody||[];
                }else{
                    this.ticketInfos=[];
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('加载券数据失败：'+e,);
                console.log('加载券数据失败：'+e);
            })
        },
        init(){
            (this.ticketInfos||[]).map((item)=>{
                item._selected=false;
            })
        },
        getTicketList(Kind){
            let searchText=this.searchText;
            if(searchText){
                return (this.ticketInfos||[]).filter(item=> 
                    item.Kind==Kind && (item.Tk_Name||"").toLowerCase().indexOf(searchText)>=0 
                );
            }
            return (this.ticketInfos||[]).filter(item=>item.Kind==Kind);
        },
        hide(){
            this.$emit("closeModel");
        },
        onTicket(item){
            item._selected=!item._selected;
        },
        /**确定和回车事件 */
        confirm(){ 
            let selectList=[];
            (this.ticketInfos).map(item=>{
                if(item._selected){
                    selectList.push({Tk_Autoid:item.Tk_Autoid,Kind:item.Kind,Tk_Code:item.Tk_Code,Tk_Name:item.Tk_Name});
                }
            })
            if(selectList.length==0){
                this.$message.warning('请先选择优惠券!');
                return;
            }
            this.$emit("confirm",selectList);
        }
    }
}
</script>

<style lang="sass">
@import "./couponSelectModel.scss";
</style>